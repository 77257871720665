import { Dispatch } from 'redux';
import serializeQueryParams from '../utils/serialize-query-params';

enum ACTIONS_TYPES {
    NEXT_STEP = 'tour/NEXT_STEP',
    FINISH_TOUR = 'tour/FINISH_TOUR',
    TRIGGER_TOUR = 'tour/TRIGGER_TOUR',
    GET_FROM_STORAGE = 'tour/GET_FROM_STORAGE'
}

export enum NEW_FEATURES_TOUR {
    SIMPLIFIED_HEADER = 'simplifiedHeader',
    SCHOOLS_SLIDER = 'schoolsSlider',
    SUBJECTS_SLIDER = 'subjectsSlider'
}

export type NewFeaturesTourStepType = {
    text: string;
    selector: string;
    isClickable?: boolean;
};

export type NewFeaturesTourType = {
    name: NEW_FEATURES_TOUR;
    isFinished: boolean;
    currentStep: number;
    steps: NewFeaturesTourStepType[];
};

const isFinished = !Number(serializeQueryParams().newFetureTour === undefined || serializeQueryParams().newFetureTour);
const initialState: NewFeaturesTourType[] = [
    {
        name: NEW_FEATURES_TOUR.SIMPLIFIED_HEADER,
        isFinished,
        currentStep: 0,
        steps: [
            {
                text: 'Die bisherigen Symbole findest du jetzt in der oberen Leiste.',
                selector: '#extra-navigation-buttons',
                isClickable: true
            },
            {
                text: 'Neu: Entdecke Materialien nach Schulform, Klassenstufe und Fach.',
                selector: '#schools-burger-button',
                isClickable: true
            }
        ]
    },
    {
        name: NEW_FEATURES_TOUR.SCHOOLS_SLIDER,
        isFinished,
        currentStep: 0,
        steps: [
            {
                text: 'Wähle eine Schulform, um passende Fächer zu erkunden.',
                selector: '#schools-slider',
                isClickable: true
            }
        ]
    },
    {
        name: NEW_FEATURES_TOUR.SUBJECTS_SLIDER,
        isFinished,
        currentStep: 0,
        steps: [
            {
                text: 'Durchstöbere die Fächer und Kategorien über den Schieberegler.',
                selector: '#subject-slider',
                isClickable: true
            },
            {
                text: 'Nutze die Pfad-Navigation, um vor- und zurückzuspringen.',
                selector: '#school-breadcrumbs',
                isClickable: true
            }
        ]
    }
];

export default (
    state = initialState,
    {
        type,
        payload
    }: {
        type: ACTIONS_TYPES;
        payload: {
            name: NEW_FEATURES_TOUR;
            feature: Partial<NewFeaturesTourType>;
            features: Partial<NewFeaturesTourType>[];
        };
    }
) => {
    switch (type) {
        case ACTIONS_TYPES.GET_FROM_STORAGE: {
            return state.map((tour) => {
                const updatedFeature = payload.features.find((f) => f.name === tour.name);

                return updatedFeature ? { ...tour, ...updatedFeature } : tour;
            });
        }
        case ACTIONS_TYPES.NEXT_STEP:
            return state.map((tour) =>
                tour.name === payload.name ? { ...tour, currentStep: tour.currentStep + 1 } : tour
            );
        case ACTIONS_TYPES.FINISH_TOUR:
            return state.map((tour) => (tour.name === payload.name ? { ...tour, isFinished: true } : tour));
        case ACTIONS_TYPES.TRIGGER_TOUR:
            return [...state];
        default:
            return state;
    }
};
export const getFeatureTourFromStorage = () => (dispatch: Dispatch) => {
    const data = getNewFeatures();
    let features = Object.keys(data).map((key) => ({ name: key, isFinished: Boolean(data[key]) }));
    const params = serializeQueryParams();

    if (params.newFetureTour !== undefined) {
        features = features.map((feature) => ({ ...feature, isFinished }));
    }

    dispatch({ type: ACTIONS_TYPES.GET_FROM_STORAGE, payload: { features } });
};

export const nextStep = (name: NEW_FEATURES_TOUR) => ({ type: ACTIONS_TYPES.NEXT_STEP, payload: { name } });

export const triggerTour = () => (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const isAllCompleted = !state.main.newFeaturesTour.find((tour) => !tour.isFinished);

    if (isAllCompleted) {
        return;
    }

    setTimeout(() => dispatch({ type: ACTIONS_TYPES.TRIGGER_TOUR }), 200);
};

export const finishTour = (name: NEW_FEATURES_TOUR) => (dispatch: Dispatch) => {
    const data = getNewFeatures();
    const updatedData = { ...data, [name]: 1 };

    window.storage.setItem('newFeatureTour', JSON.stringify(updatedData));

    dispatch({ type: ACTIONS_TYPES.FINISH_TOUR, payload: { name } });
};

const getNewFeatures = () => {
    return JSON.parse(window.storage.getItem('newFeatureTour')) || {};
};
