import API_URLS from '../constants/api-urls';
import Routes from '../constants/routes';
import { declineAgb, getUser, setLoaded } from '../reducers/user';
import store from '../store';
import axios from '../utils/axios';
import detectBrowser from '../utils/detect-browser';
import detectDevice from '../utils/detect-device';
import isUITest from '../utils/is-ui-test';
import session from '../utils/session';
import logout from './logout';
import qualityEvents from './quality-events';

const CLIENT_ID = 'oCGxyq24sKTcnvsr';

const checkWrongAccountLogin = (response = {}) => {
    // This condition is experimental – here we track wrongAccount logins.
    // Sometimes users find out that they are logged into wrong accounts
    // And to fix it we compare ids from /authenticate (stored in localStorage) and /validate requests
    const { oauth_user_id } = response;
    const stored_oauth_user_id = window.storage.getItem('oauth_user_id');
    const LOGOUT_DELAY = 1000;

    if (
        oauth_user_id &&
        stored_oauth_user_id &&
        session.isLoggedIn() &&
        !session.impersonated() &&
        window.location.href.indexOf(Routes.SIGN_UP_CONFIRM) < 0
    ) {
        if (Number(oauth_user_id) !== Number(stored_oauth_user_id)) {
            if (!isUITest()) {
                qualityEvents.track({
                    extra: JSON.stringify({
                        new_id: oauth_user_id,
                        stored_id: stored_oauth_user_id
                    }),
                    page_url: window.location.href,
                    type: 'wrongAccountLogin:byValidate',
                    user_agent: detectBrowser(),
                    user_device: detectDevice()
                });
            }

            setTimeout(logout, LOGOUT_DELAY); // time to send QE
        }
    }
};

const oauth = (() => {
    const deferred = { isAgbAccepted: true };

    return {
        apple(params) {
            return new Promise((resolve, reject) => {
                this.authenticate({
                    client_id: CLIENT_ID,
                    grant_type: 'apple_id',
                    platform: 'web',
                    ...params
                }).then(
                    (response) => {
                        session.setAccessToken(response.access_token);
                        session.clearError();
                        session.setLoggedIn(1);
                        resolve(response);
                    },
                    (error) => reject(error)
                );
            });
        },
        authenticate(params, headers) {
            return axios.post(
                API_URLS.AUTHENTICATE,
                {
                    scopes: ['full'],
                    ...params
                },
                { headers }
            );
        },
        changeDeferred(key, value) {
            deferred[key] = value;
        },
        clientCredentials() {
            if (deferred.credentials) {
                return deferred.credentials;
            }

            deferred.credentials = new Promise((resolve, reject) => {
                this.authenticate({
                    client_id: CLIENT_ID,
                    grant_type: 'client_credentials',
                    scopes: ['public']
                }).then(
                    (response) => {
                        session.setLoggedIn(0);
                        resolve(response);
                    },
                    (error) => reject(error)
                );
            });

            return deferred.credentials;
        },
        facebook(token) {
            return new Promise((resolve, reject) => {
                this.authenticate({
                    client_id: CLIENT_ID,
                    facebook_access_token: token,
                    grant_type: 'facebook'
                }).then(
                    (response) => {
                        session.setAccessToken(response.access_token);
                        session.clearError();
                        session.setLoggedIn(1);
                        resolve(response);
                    },
                    (error) => reject(error)
                );
            });
        },
        google(params) {
            return new Promise((resolve, reject) => {
                this.authenticate({
                    client_id: CLIENT_ID,
                    grant_type: 'google',
                    platform: 'web',
                    ...params
                }).then(
                    (response) => {
                        session.setAccessToken(response.access_token);
                        session.clearError();
                        session.setLoggedIn(1);
                        resolve(response);
                    },
                    (error) => reject(error)
                );
            });
        },

        isAgbAccepted() {
            return deferred.isAgbAccepted;
        },

        login(type = 'password', params) {
            deferred.validation = null;

            return new Promise((resolve, reject) => {
                this[type](params).then(
                    (response) => {
                        session.setAccessToken(response.access_token);
                        session.clearError();
                        resolve(response);
                    },
                    (error) => reject(error)
                );
            });
        },

        microsoft(params) {
            return new Promise((resolve, reject) => {
                this.authenticate({
                    client_id: CLIENT_ID,
                    grant_type: 'microsoft',
                    ...params
                }).then(
                    (response) => {
                        session.setAccessToken(response.access_token);
                        session.clearError();
                        session.setLoggedIn(1);
                        resolve(response);
                    },
                    (error) => reject(error)
                );
            });
        },
        password({ username, password } = {}) {
            return new Promise((resolve, reject) => {
                this.authenticate({
                    client_id: CLIENT_ID,
                    grant_type: 'password',
                    password,
                    username
                }).then(
                    (response) => {
                        if (response?.oauth_user_id) {
                            window.storage.setItem('oauth_user_id', response.oauth_user_id);
                        }

                        session.setLoggedIn(1);
                        window.storage.setItem('impersonated', 0);
                        resolve(response);
                    },
                    (error) => reject(error)
                );
            });
        },
        validate() {
            if (deferred.validation) {
                return Promise.resolve(deferred.validation);
            }

            if (deferred.isPending) {
                return deferred.pendingPromise;
            }

            deferred.isPending = true;
            // deferred.isAgbAccepted = false;

            deferred.pendingPromise = new Promise((resolve, reject) => {
                axios.get(API_URLS.VALIDATE).then(
                    (response) => {
                        checkWrongAccountLogin(response);

                        if (response) {
                            window.storage.setItem('access_token', response.oauth_access_token);
                        }

                        if (response?.oauth_user_id && window.location.href.indexOf(Routes.SIGN_UP_CONFIRM.path) < 0) {
                            session.setLoggedIn(1);
                            window.storage.setItem('userId', response.oauth_user_id);
                            getUser()(store.dispatch, store.getState);
                        } else {
                            session.setLoggedIn(0);
                            setLoaded()(store.dispatch);
                        }

                        deferred.validation = true;
                        deferred.isAgbAccepted = true;
                        deferred.isPending = false;
                        resolve(response);
                    },
                    (error) => {
                        // @ts-ignore
                        if (error?.data?.reason === 'terms_not_accepted') {
                            deferred.isAgbAccepted = false;
                            deferred.validation = false;
                            declineAgb()(store.dispatch);
                            deferred.isPending = false;

                            return reject(error);
                        }

                        oauth.login('clientCredentials').then(
                            () => {
                                session.setLoggedIn(0);
                                setLoaded()(store.dispatch);
                                deferred.validation = false;
                                deferred.isAgbAccepted = true;
                                deferred.isPending = false;
                                resolve();
                            },
                            () => {
                                session.setLoggedIn(0);
                                session.setError('authError');
                                deferred.isPending = false;
                            }
                        );
                    }
                );
            });

            return deferred.pendingPromise;
        }
    };
})();

export default oauth;
