import { combineReducers } from 'redux';
import cart from './cart';
import cookieConsent from './cookie-consent';
import currency from './currency';
import favorite from './favorite';
import filters from './filters';
import folders from './folders';
import geotargeting from './geotargeting';
import newFeaturesTour from './new-features-tour';
import schoolClassification from './school-classification';
import searchResults from './search-results';
import user from './user';
import websocket from './websocket';

const main = combineReducers({
    cart,
    cookieConsent,
    currency,
    favorite,
    filters,
    schoolClassification,
    newFeaturesTour,
    folders,
    geotargeting,
    searchResults,
    user,
    websocket
});

export default main;
