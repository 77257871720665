import { BaseService } from '@usercentrics/cmp-browser-sdk';
import { AGB_ANCHOR_NAMES, getAGBAnchor } from '../constants/agb';
import Routes from '../constants/routes';
import { world } from '../languages';
import { AVAILABLE_WORLDS } from '../languages/constants';
import { initCookiesConsent } from '../reducers/cookie-consent';
import store from '../store';
import isMobileAppWebview from '../utils/is-mobile-app-webview';
import isPrerender from '../utils/is-prerender';
import isUITest from '../utils/is-ui-test';

export enum USERCENTRICS_SERVICES {
    APPLE_LOGIN = 'Apple Sign-in',
    BRAZE = 'Braze',
    FACEBOOK = 'Facebook',
    FACEBOOK_SOCIAL = 'Facebook Social Plugins',
    GA = 'Google Analytics',
    GOOGLE = 'Google Sign-In',
    GTM = 'Google Tag Manager',
    MICROSOFT_AZURE = 'Microsoft Azure',
    PINTEREST = 'Pinterest',
    ZENDESK = 'Zendesk'
}

export const UC_RETRYING_COUNT = 10;
export const UC_RETRYING_DELAY = 1000; // 1s

const usercentrics = {
    addEventListenerOnUcImprintClick() {
        window.addEventListener('onUcImprintClick', () => {
            window.open(`${Routes.IMPRESSUM.path}`, '_blank');
        });
    },

    addEventListenerOnUcPrivacyClick() {
        window.addEventListener('onUcPrivacyClick', () => {
            window.open(`${Routes.AGB.path}${getAGBAnchor(AGB_ANCHOR_NAMES.dataProtection)}`, '_blank');
        });
    },

    addEventListenerOnViewChange() {
        // Update services data after close usercentrics modal for applying changes (accepted cookies)
        window.addEventListener('UC_UI_VIEW_CHANGED', (event) => {
            // @ts-ignore
            if (event.detail.view === 'NONE') {
                initCookiesConsent()(store.dispatch);
            }
        });
    },
    getServicesData(): BaseService[] {
        const UC = window.UC_UI;
        let servicesBaseInfo = [];

        if (UC) {
            servicesBaseInfo = UC.getServicesBaseInfo();
        }

        return servicesBaseInfo;
    },
    init() {
        let retryInitializingCounter = 0;
        let isUcInitialized = false;
        const initializationInterval = setInterval(() => {
            if (isUcInitialized || retryInitializingCounter > UC_RETRYING_COUNT) {
                clearInterval(initializationInterval);

                return;
            }

            if (window.UC_UI?.isInitialized()) {
                isUcInitialized = true;
                initCookiesConsent()(store.dispatch);
                this.addEventListenerOnViewChange();
                this.addEventListenerOnUcPrivacyClick();
                this.addEventListenerOnUcImprintClick();
            } else {
                retryInitializingCounter++;
            }
        }, UC_RETRYING_DELAY);

        // Try to show modal every time until accept because after AGB page (where modal is hidden) we can go to any page
        const tryToShowInterval = setInterval(() => {
            if (this.isShown || (window.UC_UI && !window.UC_UI.isConsentRequired())) {
                clearInterval(tryToShowInterval);

                return;
            }
            this.showModalIfNecessary();
        }, UC_RETRYING_DELAY);
    },

    isServiceAccepted(services: BaseService[], serviceName: string | string[]): boolean {
        // Skip checking for tests and pre-rendering because in these cases all third party libs are allowed
        if (isUITest() || isPrerender()) {
            return true;
        }

        if (window.UC_UI?.isConsentRequired()) {
            return false;
        }

        if (typeof serviceName === 'string') {
            serviceName = [serviceName];
        }

        return services
            .filter((service) => serviceName.includes(service.name))
            .every((service) => Boolean(service.consent.status));
    },

    isShown: false,

    showModalIfNecessary() {
        if (
            !this.isShown &&
            window.UC_UI?.isConsentRequired() &&
            window.location.pathname.indexOf(Routes.AGB.path) < 0 &&
            window.location.pathname.indexOf(Routes.IMPRESSUM.path) < 0 &&
            !isMobileAppWebview()
        ) {
            const language =
                {
                    [AVAILABLE_WORLDS.CN]: 'zh',
                    [AVAILABLE_WORLDS.CZ]: 'cs',
                    [AVAILABLE_WORLDS.DK]: 'da',
                    [AVAILABLE_WORLDS.GR]: 'el',
                    [AVAILABLE_WORLDS.JP]: 'ja',
                    [AVAILABLE_WORLDS.KR]: 'ko',
                    [AVAILABLE_WORLDS.KZ]: 'kk',
                    [AVAILABLE_WORLDS.MY]: 'ms',
                    [AVAILABLE_WORLDS.RS]: 'sr_latn',
                    [AVAILABLE_WORLDS.SE]: 'sv',
                    [AVAILABLE_WORLDS.UA]: 'uk'
                }[world] || world;

            window.UC_UI.updateLanguage(language);
            window.UC_UI.showFirstLayer();

            this.isShown = !this.isShown;
        }
    }
};

export default usercentrics;
