export enum SCHOOL_TYPES {
    PRIMARY_SCHOOL = 'ground',
    SECONDARY_SCHOOL = 'secondary'
}

//order matters
export enum SCHOOLS_CLASSIFICATION {
    ALL_SCHOOLS = 'alle',
    PRESCHOOL = 'kita',
    PRIMARY_SCHOOL = 'grundschule',
    SECONDARY_I_MIDDLE = 'sek1-mittel',
    SECONDARY_I_GYMANSIUM = 'sek1-gym',
    SECONDARY_II = 'sek2',
    VOCATIONAL_SCHOOL = 'berufsschule',
    REMEDIAL_TEACHING = 'foerder',
    ADULT_EDUCATION = 'erwachsene'
}
