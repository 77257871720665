import { Dispatch } from 'redux';
import API_URLS from '../constants/api-urls';
import { SCHOOLS_CLASSIFICATION } from '../constants/school-types';
import { initUnleashAbTest } from '../hooks/use-unleash-ab-test';
import axios from '../utils/axios';

enum ACTIONS_TYPES {
    SCHOOLS_GET = 'schools/GET',
    NAVIGATION_AB_TEST_GET = 'navigation-ab-test/GET'
}

type SchoolSubject = {
    id: number;
    materialsCount: number;
    title: string;
};

export type SchoolClassification = {
    schoolName: string;
    grades: number[];
    type: SCHOOLS_CLASSIFICATION;
    subjects: SchoolSubject[];
};

type SchoolClassificationState = {
    isLoaded: boolean;
    isActiveNavigationAbTest: boolean;
    schools: SchoolClassification[];
};

const initialState: SchoolClassificationState = {
    isLoaded: false,
    isActiveNavigationAbTest: false,
    schools: []
};

export default (state = initialState, action: { type: ACTIONS_TYPES; payload: Partial<SchoolClassificationState> }) => {
    switch (action.type) {
        case ACTIONS_TYPES.NAVIGATION_AB_TEST_GET:
            return { ...state, ...action.payload };
        case ACTIONS_TYPES.SCHOOLS_GET:
            return { ...state, ...action.payload, isLoaded: true };
        default:
            return state;
    }
};

export const initNavigationAbTest = () => (dispatch: Dispatch) => {
    initUnleashAbTest({
        gaDimension: 'testSegment7',
        gaTestName: 'NAV',
        hardApplyParam: 'Navigation',
        unleashKey: 'Navigation'
    }).then((unleashVersion) => {
        const isActiveNavigationAbTest = ['D'].includes(unleashVersion[0]);

        dispatch({ type: ACTIONS_TYPES.NAVIGATION_AB_TEST_GET, payload: { isActiveNavigationAbTest } });
    });
};

export const getSchools = () => async (dispatch: Dispatch, getState: () => RootState) => {
    if (getState().main.schoolClassification.isLoaded) {
        return;
    }

    const res = await axios.get(API_URLS.SUBJECTS_MENU);

    if (res) {
        const schools = parseSubjectsResponse(res);

        dispatch({ type: ACTIONS_TYPES.SCHOOLS_GET, payload: { schools } });
    }
};

const parseSubjectsResponse = (
    schools: {
        grades: number[];
        school_name: string;
        subjects: { id: number; doc_count: number; material_category: string }[];
    }[]
): SchoolClassification[] => {
    return schools.map(({ school_name, grades, subjects }, idx) => ({
        schoolName: school_name,
        grades,
        type: Object.values(SCHOOLS_CLASSIFICATION)[idx + 1],
        subjects: subjects.map(({ id, doc_count, material_category }) => ({
            id,
            materialsCount: doc_count,
            title: material_category
        }))
    }));
};
