import { Dispatch } from 'redux';
import API_URLS from '../constants/api-urls';
import Routes from '../constants/routes';
import IconLoggedUser from '../images/logged-user-icon.svg';
import personalizationStatus from '../services/personalization-status';
import axios from '../utils/axios';
import clearStorage from '../utils/clear-storage';
import emailHash from '../utils/email-hash';
import imagePathCreator from '../utils/image-path-creator';
import lmpPersonalizationPopupPermissions from '../utils/lmp-personalization-popup-permissions';
import makeUrl from '../utils/make-url';
import pubsub from '../utils/pubsub';
import SchoolAccount from '../utils/school-account';
import session from '../utils/session';
import { setUserData } from '../utils/user';
import { setCurrency } from './currency';

export const USER_GET = 'user/GET';
export const PERSONALIZATION_GET = 'p/GET';
export const CHANGE_USER_STATE = 'chu';
export const CHANGE_USER_DETAILS = 'changeUserDetails';
export const ACCEPT_AGB = 'acceptAgb';
export const DECLINE_AGB = 'declineAgb';

const initialState: User = {
    details: {} as UserDetails,
    doubleOptIn: true,
    isAgbAccepted: true,
    loaded: false,
    userImagePath: ''
} as User;

type UserActionType = { type: string; payload?: Partial<typeof initialState | UserDetails> };

export default (state = initialState, action: UserActionType) => {
    switch (action.type) {
        case CHANGE_USER_STATE:
        case PERSONALIZATION_GET:
            return {
                ...state,
                ...action.payload
            };
        case USER_GET:
            return {
                ...state,
                ...action.payload,
                loaded: true
            };
        case CHANGE_USER_DETAILS:
            return {
                ...state,
                details: { ...state.details, ...action.payload }
            };
        case ACCEPT_AGB:
            return {
                ...state,
                isAgbAccepted: true
            };
        case DECLINE_AGB:
            return {
                ...state,
                isAgbAccepted: false
            };
        default:
            return state;
    }
};

export const getUser = () => (dispatch: Dispatch, getState: () => RootState) => {
    axios.get(API_URLS.USER_DATA).then(
        (user) => {
            axios.get(`${API_URLS.USER_DATA}/details`).then(
                (userDetails) => {
                    const userData = user.data.items[0];
                    const { main } = getState();

                    if (window.location.pathname === Routes.AUTHOR_REDIRECT.path) {
                        if (!userData.isSeller) {
                            window.location.href = Routes.CABINET_SALES.path;
                        }

                        window.location.href = makeUrl(Routes.AUTHOR_MAIN.path, {
                            id: userData.id,
                            slug: userData.slug
                        });
                    }

                    userData.userImagePath = userData.details.imagePath
                        ? imagePathCreator.croppedImg({ url: userData.details.imagePath }, { h: 32, q: 95, w: 32 })
                        : IconLoggedUser;

                    userData.userName = userData.details.publicName;

                    window.storage.setItem('userId', userData.id);
                    window.storage.setItem('creationSource', userData.creationSource);
                    window.storage.setItem('userSlug', userData.slug);
                    window.storage.setItem('isSeller', userData.isSeller);
                    window.storage.setItem('userSD', userData.createdAt ? userData.createdAt : '');
                    emailHash.storeMD5(userData.email);
                    emailHash.storeSHA256(userData.email);

                    userData.profileDetails = userDetails.data.items;
                    userData.profileDetails.invoiceDetails.clientEmail = userData.profileDetails.invoiceDetails.email;
                    userData.loggedInAsAnotherUser = window.storage.getItem('impersonated') === '1';
                    userData.availableCredits = userData.profileDetails.details.credits
                        ? parseFloat(userData.profileDetails.details.credits.amount)
                        : 0;
                    userData.hadCredits = userData.profileDetails.details.credits
                        ? userData.profileDetails.details.credits.had_credits
                        : false;

                    // if (userData.profileDetails && userData.profileDetails.details) {
                    //     $rootScope.ADMIN_MODE_AVAILABLE = userData.profileDetails.details.searchMode;
                    //     $rootScope.WORK_IN_ADMIN_MODE = storage.getItem('am');
                    // }

                    setUserData(userData);

                    dispatch({
                        type: USER_GET,
                        payload: userData
                    });

                    setCurrency({
                        code: userData.profileDetails?.details?.currencyCode
                    })(dispatch);

                    // if (schoolAccount.isAdmin(userData)) {
                    //     setCurrencyInfo({ exchangeRate: 3 })(dispatch);
                    // }
                    axios({
                        baseURL: process.env.REACT_APP_API_URL,
                        headers: { Authorization: `Bearer ${session.getAccessToken()}` },
                        url: 'v3/personalisations/get'
                        // @ts-ignore
                    }).then((r: { result: Partial<User>; showPopup: boolean; needActualisation: boolean }) => {
                        const preferences = r.result || [];
                        const permissions = lmpPersonalizationPopupPermissions();

                        if (
                            r.showPopup &&
                            permissions.showPopup &&
                            !main?.user?.personalisationRequested &&
                            !SchoolAccount.isAdmin(main?.user)
                        ) {
                            setTimeout(() => {
                                pubsub.publish('showPersonalization', {
                                    preferences,
                                    type: 'auto'
                                });
                            }, 10000);
                        }

                        dispatch({
                            payload: {
                                needActualisation: r.needActualisation,
                                personalisationRequested: true,
                                preferences,
                                showPopup: r.showPopup
                            },
                            type: PERSONALIZATION_GET
                        });
                    });
                },
                () => clearStorage()
            );
        },
        () => clearStorage()
    );
};

export const updateP13nStatus = () => (dispatch: Dispatch) => {
    personalizationStatus.update().then(() => {
        dispatch({
            payload: {
                needActualisation: false,
                showPopup: false
            },
            type: PERSONALIZATION_GET
        });
    });
};

export const setLoaded = () => (dispatch: Dispatch) => {
    dispatch({
        payload: {
            loaded: true
        },
        type: CHANGE_USER_STATE
    });
};

export const changeUserDetails = (details: Partial<UserDetails>) => (dispatch: Dispatch) => {
    dispatch({
        payload: details,
        type: CHANGE_USER_DETAILS
    });
};

export const acceptAgb = () => (dispatch: Dispatch) => {
    dispatch({
        type: ACCEPT_AGB
    });
};

export const declineAgb = () => (dispatch: Dispatch) => {
    dispatch({
        type: DECLINE_AGB
    });
};
