import session from '../utils/session';

export type DataLayerProps = {
    dynamicURL?: string;
    ecommerce?: unknown;
    event?: string;
    eventAction?: string;
    eventCategory?: string;
    eventLabel?: string;
    eventNonInteraction?: boolean;
    eventValue?: number;
    product?: {
        id: string | number;
    };
    page?: {
        searchResults?: string;
        selectedAuthor?: string;
        selectedGrades?: string;
        selectedSubject?: string;
        selectedType?: string;
        type?: string;
    };
    emailSHA256?: string;
    pinterestInfo?: { emailHash: string };
    referrer?: string;
    testSegment?: string;
    testSegment0?: string;
    testSegment1?: string;
    testSegment10?: string;
    testSegment11?: string;
    testSegment12?: string;
    testSegment13?: string;
    testSegment14?: string;
    testSegment2?: string;
    testSegment3?: string;
    testSegment4?: string;
    testSegment5?: string;
    testSegment6?: string;
    testSegment7?: string;
    testSegment8?: string;
    testSegment9?: string;
    user?: unknown;
};

const analytics = {
    push(params: DataLayerProps) {
        if (!session.impersonated()) {
            window?.dataLayer?.push(params);
        }
    }
};

export default analytics;
