import { useState } from 'react';
import abAvailability from '../services/ab-availability';
import serializeQueryParams from '../utils/serialize-query-params';

type Props = {
    gaDimension: string;
    gaTestName: string;
    hardApplyParam?: string;
    unleashKey?: string;
    group?: string;
};

const existedGroupsWithFeatures = {
    TestGroup: ['TGF1', 'TGF2', 'TGF3'],
    TestGroup2: ['TG2F1', 'TG2F2', 'TG2F3'],
    TestRandomGroup: ['TRGF1', 'TRGF2', 'TRGF3'],
    TestRandomGroup2: ['TRG2F1', 'TRG2F2', 'TRG2F3']
};

const testValues = {
    groups: {},
    features: {}
};

const cleanStoredDataForGroupFeatures = (testSegment: string, groupName: string) => {
    const storedData = window?.storage?.getItem(testSegment);

    if (existedGroupsWithFeatures[groupName]?.some((feature: string) => storedData?.includes(feature))) {
        let updatedData = {};

        try {
            const parsedStoredData = JSON.parse(storedData);

            existedGroupsWithFeatures[groupName].forEach((feature: string) => {
                if (parsedStoredData[feature]) {
                    delete parsedStoredData[feature];
                }
            });

            updatedData = parsedStoredData;
        } catch {}

        window?.storage?.setItem(testSegment, JSON.stringify(updatedData));
    }
};

const storeUnleashGroupsData = (groupName: string, abTestKey: string) => {
    let data = {
        [groupName]: {
            features: existedGroupsWithFeatures[groupName],
            value: abTestKey
        }
    };
    const existedGroups = window?.storage?.getItem('unleashGroups');

    if (existedGroups) {
        try {
            const existedGroupsData = JSON.parse(existedGroups);

            data = Object.assign(existedGroupsData, data);
        } catch {}
    }

    window.storage.setItem('unleashGroups', JSON.stringify(data));
};

const getUnleashGroup = (group = '') => {
    return new Promise((resolve) => {
        if (!group) {
            return resolve('');
        }

        const existedGroups = window?.storage?.getItem('unleashGroups');

        if (existedGroups) {
            try {
                const existedGroupData = JSON.parse(existedGroups)[group];

                if (JSON.stringify(existedGroupData?.features?.sort()) === JSON.stringify(existedGroupsWithFeatures[group]?.sort())) {
                    return resolve(existedGroupData.value);
                }
            } catch {}
        } else {
            cleanStoredDataForGroupFeatures('testSegment', group);
            for(let i = 0; i < 15; i++) {
                cleanStoredDataForGroupFeatures(`testSegment${i}`, group);
            }
        }

        let variantName = '';

        // Select group by random
        if (group.includes('TestRandomGroup')) {
            const groupVariants = existedGroupsWithFeatures[group];
            const randomIndex = Math.floor(Math.random() * groupVariants.length);
            const randomGroupVariant = groupVariants[randomIndex];

            storeUnleashGroupsData(group ,randomGroupVariant);

            return resolve(randomGroupVariant);
        }

        abAvailability.get(group).then((response) => {
            variantName = response?.variant?.name;

            if (variantName === 'disabled') {
                return;
            }

            storeUnleashGroupsData(group ,variantName);
        }).finally(() => {
            resolve(variantName);
        });
    });
};

export const getUnleashTest = ({ gaDimension, unleashKey = '', hardApplyParam = '', gaTestName = '', group = '' }: Props) => {
    const params = serializeQueryParams();

    return new Promise((setValue) => {
        const param = params[hardApplyParam] || params[unleashKey];

        if (param) {
            const mapper = {
                0: 'O',
                1: 'B',
                2: 'C',
                3: 'D',
                4: 'E',
                5: 'F',
                6: 'G'
            };

            if (Number.isNaN(Number(param))) {
                setValue(param as string);
            } else {
                setValue(mapper[Number(param)]);
            }
        } else {
            const existedVersion = window?.storage?.getItem(gaDimension);

            if (gaTestName && group && gaTestName !== group) {
                const storedData = window?.storage?.getItem(gaDimension);

                try {
                    const parsedStoredData = JSON.parse(storedData);

                    parsedStoredData[gaTestName] = 'A: Default';
                    window?.storage?.setItem(gaDimension, JSON.stringify(parsedStoredData));
                } catch {
                    window?.storage?.setItem(gaDimension, JSON.stringify({
                        [gaTestName]: 'A: Default'
                    }));
                }

                return setValue('A');
            }

            if (existedVersion?.indexOf(gaTestName) > -1){
                let variant = existedVersion;

                if (gaTestName) {
                    try {
                        variant = JSON.parse(existedVersion)[gaTestName];
                    } catch {
                        variant = existedVersion.slice(0, existedVersion.indexOf(gaTestName));
                        window.storage.setItem(gaDimension, JSON.stringify({ [gaTestName]: variant }));
                    }
                }

                setValue(variant);
            } else {
                let variantName = 'A'; // By default AB test has original variant

                abAvailability.get(unleashKey || gaDimension).then((response) => {
                    variantName = response?.variant?.name;
                    const variantValue = response?.variant?.payload?.value;

                    if (variantName === 'disabled') {
                        return;
                    }

                    if (variantName === 'Original') {
                        variantName = 'O';
                    }

                    if (variantValue?.[0] === ':') {
                        variantName += variantValue;
                    }

                    let data = { [gaTestName]: variantName };

                    try {
                        const gaData = JSON.parse(window.storage.getItem(gaDimension));

                        if (gaData && Object.keys(gaData).length < 10) {
                            gaData[gaTestName] = variantName;
                            data = gaData;
                        }
                    } finally {
                        window.storage.setItem(gaDimension, JSON.stringify(data));
                    }
                }).finally(() => {
                    setValue(variantName);
                });
            }
        }
    });
};

const initUnleashGroup = (group = ''): Promise<string> => {
    return new Promise((resolve) => {
        if (!group) {
            return resolve('');
        }

        if (testValues.groups[group]?.loaded) {
            return resolve(testValues.groups[group]?.value);
        }

        if (!testValues.groups[group]?.loading) {
            testValues.groups[group] = {
                loading: true,
                promise: getUnleashGroup(group)
            };
        }

        return testValues.groups[group]?.promise.then((val: string) => {
            testValues.groups[group] = { loaded: true, value: val };
            resolve(val);
        });
    });
};

export const initUnleashAbTest = ({ gaDimension, unleashKey = '', hardApplyParam = '', gaTestName = '', group = '' }: Props): Promise<string> => {
    return new Promise((resolve) => {
        if (testValues.features[gaTestName]?.loaded) {
            return resolve(testValues.features[gaTestName]?.value);
        }

        if (!testValues.features[gaTestName]?.loading) {
            testValues.features[gaTestName] = {
                loading: true,
                promise: getUnleashTest({ gaDimension, gaTestName, hardApplyParam, unleashKey, group })
            };
        }
        
        return testValues.features[gaTestName]?.promise.then((val: string) => {
                    testValues.features[gaTestName] = { loaded: true, value: val }
                    resolve(val)
                });
    });
};

const useUnleashAbTest = ({ gaDimension, unleashKey = '', hardApplyParam = '', gaTestName = '', group = '' }: Props) => {
    const [value, setValue] = useState('');

    const onGetValue = (val: string) => {
        const abTestVariant = val?.split(':')[0];

        setValue(abTestVariant);
    }
    
    const init = () => {
        const params = serializeQueryParams();
        const param = params[hardApplyParam] || params[unleashKey];

        if (param) {
            initUnleashAbTest({ gaDimension, gaTestName, hardApplyParam, unleashKey }).then(onGetValue);
        } else {
            initUnleashGroup(group)
                .then(groupVariant => initUnleashAbTest({ gaDimension, gaTestName, hardApplyParam, unleashKey, group: groupVariant }))
                .then(onGetValue);
        }
    }

    return { initUnleashTest: init, unleashVersion: value };
};

export default useUnleashAbTest;
