import { AVAILABLE_WORLDS } from '../languages/constants';
import countryByIP from '../services/country-by-ip';
import session from '../utils/session';
import { setCurrency } from './currency';

export const GEOTARGETING_SET = 'geotargeting/SET';

const initialState: GeotargetingState = {
    countryCode: null as unknown as string,
    loaded: false
};

export type GeotargetingState = {
    countryCode: string;
    loaded: boolean;
};

type IGeotargetingAction = {
    payload: GeotargetingState;
    type: string;
};

export default (state = initialState, action: IGeotargetingAction) => {
    switch (action.type) {
        case GEOTARGETING_SET:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export const getLocation = (): ThunkAction<void, RootState, unknown, IGeotargetingAction> => (dispatch) => {
    const setGeotargeting = (code: string) => {
        const countryCode = code.toUpperCase();

        window.storage.setItem('countryCode', countryCode);

        dispatch({
            payload: {
                countryCode,
                loaded: true
            },
            type: GEOTARGETING_SET
        });
    };

    countryByIP
        .get()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
            if (!session.isLoggedIn()) {
                setCurrency({
                    code: response.currencyCode
                })(dispatch);
            }
            setGeotargeting(response.code);
        })
        .catch(() => {
            setGeotargeting(AVAILABLE_WORLDS.DE);
        });
};
